import React,{ useEffect, useState, useContext } from "react";
import TournamentCard from "../components/TournamentCard";
import  UserContext from "../components/appContext";
import axios from "axios";
import PopUpBox from "../components/PopUpBox";

const Lobby = () => {
    const [activeGames, setActiveGames] = useState([]); 
    const [upcomingGames, setUpcomingGames] = useState([]); 
    const [completedGames, setCompletedGames] = useState([]); 
    const [showPopup, setShowPopup] = useState('hide')

    const [popUpShown, setPopUpShown] = useState(false)
    const { userId } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [ctaData , setCtaData] = useState({});

    const getTournamentData = async () =>{
        setLoading(true);
        return await axios
            .get(`${process.env.REACT_APP_DATABASE_URL}/api/lobby/games/${userId}`)
            .then((response) => {
                
                if(response && response.data && response.data.active){
                    const newList = response.data.active.sort((a, b) => (a.finished > b.finished) ? 1 : -1)
                    setActiveGames(newList); 
                }
                if(response && response.data && response.data.upcoming){
                    setUpcomingGames(response.data.upcoming);
                }
                if(response && response.data && response.data.completed){
                    setCompletedGames(response.data.completed);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    
    useEffect(() =>{
        getTournamentData();
  
    },[])

    const openPopup = (bodyText,btnText,btnLink) =>{ 
        if(!popUpShown){   

            setCtaData({ctaBodyText:bodyText, ctaBtnText:btnText, ctaBtnLink:btnLink})
            setShowPopup('show');
            setPopUpShown(true);
        }
    }

    const closePopup = () =>{
        if(showPopup  === 'hide'){
            setShowPopup('show');  
        }else{
            setShowPopup('hide');
        }

    }


    return( 
        <>
            {loading ? ( <div className="loading-text"></div>
            ):(
                <div>
                {activeGames && activeGames.map((data,index) => (
                        
                        <TournamentCard key={index} data={data} openPopup={openPopup} reloadHandler={getTournamentData}/>
                    
                    ))
                }
                {upcomingGames && upcomingGames.map((data,index) => (
                    <TournamentCard key={index} data={data}  reloadHandler={getTournamentData}/>
                    ))
                }

                {/*completedGames && completedGames.slice(0, 3).map((data,index) => (*/
                completedGames && completedGames.map((data,index) => (

                <TournamentCard key={index} data={data} openPopup={openPopup} />
                ))
                }
                
                </div>
            )}
            <PopUpBox show={showPopup} data={ctaData} close={closePopup} />
            
        </>
    );
};
  
export default Lobby;