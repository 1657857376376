import { Route,Routes, useNavigate,useLocation } from "react-router-dom";
import UserContext from "./appContext";
import React, {useState,useLayoutEffect} from "react";
import axios from "axios";

const AuthProvider =  ({children}) => {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false);
    const [loggedIn, setLoggedIn ] = useState(false);
    const [userToken, setUserToken] = useState();
    const [userName, setUserName] = useState();
    const [teamName, setTeamName] = useState();
    const [userId, setUserId] = useState();
    const [languageCode, setLanguageCode] = useState();
    const [template, setTemplate] = useState();
    const [companyName, setCompanyName] = useState();
    const [operatorName, setOperatorName] = useState();
    const [casinoLobbyUrl, setCasinoLobbyUrl] = useState();
    const [balance, setBalance] = useState();
    const [cpuLevelUp, setCpuLevelUp] = useState(false);
    const [downForMaintenance, setDownForMaintenance] = useState(false);
    const [screenNameRequired, setScreenNameRequired] = useState(false);
    const [faq, setfaq] = useState();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const [showPopup, setShowPopup] = useState("hide")
    const [ctaBodyText, setCtaBodyText] = useState("Play more slotes here")
    const [ctaBtnText, setCtaBtnText] = useState("View Slots")
    const [ctaBtnLink, setCtaBtnLink] = useState("")
    const [playMode, setPlayMode] = useState("free");
    const [urlToken, setUrlToken] = useState("");
    
    const handleLogin = async(userEmail,userPassword) =>{
      if(!isValidEmail(userEmail)){
          setErrorMessage("Email is not valid");
          return;
      }

      if(userPassword === ''){
          setErrorMessage("Please Enter a password");
          return;
      }

      return await axios
          .post(`${process.env.REACT_APP_DATABASE_URL}/api/login`,{email: userEmail, password:userPassword})
          .then((response) => {
            setErrorMessage("");
            if(response && response.data && response.data.error ==="Login Error"){
                setErrorMessage(response.data.message);
            }else if(response && response.data && response.data.error && response.data.error === "Internal Server Error"){
                alert(`Slotmasters Error\n${response.data.message !== "" ? response.data.message : response.data.error}`);   
            }else{
             
                setUserData(response.data);
                localStorage.removeItem("hbToken")
                localStorage.setItem("hbToken", JSON.stringify(response.data.token));
                setLoggedIn(true);
                let loginUrl = process.env.REACT_APP_URL_TOKEN === "true" ? `?token=${response.data.token}` : ''
                navigate('/'+loginUrl);
              
            }
          })
          .catch((error) => {
              console.log(error);
          })

  }
    const handleLogout = () =>{
        localStorage.removeItem("hbToken")
        setShowLogin(true);
        navigate('/login');
    }

    const screenNameUpdate = async(userScreenName) =>{
      setErrorMessage('');
      if(userScreenName === ''){
        setErrorMessage("Please enter a screen name");
        return;
      }
      return await axios
            .get(`${process.env.REACT_APP_DATABASE_URL}/api/setPlayerName/${userId}/${userScreenName}`)
            .then((response) => {
              setErrorMessage("");
              if(response && response.data && response.data.error ==="Player name already exists"){
                  setErrorMessage(response.data.error);
                  return false; 
              }else if(response && response.data && response.data.error && response.data.error === "Internal Server Error"){
                  alert(`Slotmasters Error\n${response.data.message !== "" ? response.data.message : response.data.error}`);  
                  return false; 
              }else if(response && response.data && response.data.message && response.data.message === "profanity"){
                  setErrorMessage("Please use a name without profanity");
                  return false; 
              }else{
                setUserName(userScreenName);
                setScreenNameRequired(false);  
                return true;  
              }
            })
            .catch((error) => {
                console.log(error);
                return false; 
            })

    }

    const registerUser = async(userActualName,userScreenName,userEmail,userPassword,userConfirmPassword,userTeam) =>{
      setErrorMessage('');
      if(userActualName === ''){
        setErrorMessage("Please enter a name");
        return;
      }
      if(userScreenName === ''){
          setErrorMessage("Please enter a screen name");
          return;
      }
      if(!isValidEmail(userEmail)){
          setErrorMessage("Email is not valid");
          return;
        }
       userTeam = userTeam ? userTeam :0;
      
      if(userPassword === ''){
          setErrorMessage("Please enter a password");
          return;
      }
      if(userPassword !== userConfirmPassword){
          setErrorMessage("Passwords must match");
          return;
      }

      return await axios
            .post(`${process.env.REACT_APP_DATABASE_URL}/api/register`,{email: userEmail, password:userPassword, screenName: userScreenName, actualName:userActualName, teamId:userTeam})
            .then((response) => {
              setErrorMessage("");
              if(response && response.data && response.data.error ==="Login Error"){
                  setErrorMessage(response.data.message);
              }else if(response && response.data && response.data.error && response.data.error === "Internal Server Error"){
                  alert(`Slotmasters Error\n${response.data.message !== "" ? response.data.message : response.data.error}`);   
              }else{
                  setUserData(response.data);
                  localStorage.removeItem("hbToken")
                  localStorage.setItem("hbToken", JSON.stringify(response.data.token));
                  setLoggedIn(true);
                  let loginUrl = process.env.REACT_APP_URL_TOKEN === "true" ? `?token=${response.data.token}` : ''
               
                  navigate('/' +loginUrl);
              }
            })
            .catch((error) => {
                console.log(error);
            })
    
    }

    const authenticateUser = async(url) =>{

      return await axios
            .post(`${process.env.REACT_APP_DATABASE_URL}/api/authenticate`,{url:url})
            .then((response) => {
              if(response && response.data && response.data.error && response.data.error ==="Login Required"){
                setLoggedIn(false);
                const savedToken = localStorage.getItem("hbToken");
                if(savedToken){
                  authenticateUser(window.location.href +"?token=" +JSON.parse(savedToken));
                }else{
                  setShowLogin(true);
                  navigate('/login');
                }
              }else if(response && response.data && response.data.error && response.data.error === "Can't find player by token"){
                  if(process.env.REACT_APP_BUILD === "live"){
                    alert(`Slotmasters Error\n${response.data.message !== "" ? response.data.message : response.data.error}`);
                  }else{
                    setLoggedIn(false);
                    setShowLogin(true);
                    navigate('/login');
                  }
              }else if(response && response.data && response.data.error && response.data.error === "Internal Server Error"){
                  alert(`Slotmasters Error\n${response.data.message !== "" ? response.data.message : response.data.error}`);
              }else{
                if(response && response.data){
                  setUserData(response.data);
                  if(response.data.downForMaintenance === 1){
                    navigate('/maintenance');
                  }else{
                    setLoggedIn(true);
                    //navigate('/');
                    let loginUrl = process.env.REACT_APP_URL_TOKEN === "true" ? `?token=${response.data.token}` : ''
               
                    const origin = location.state?.from?.pathname || '/'+loginUrl;
                    navigate(origin);
                  }
                }
              }
            })
            .catch((error) => {
                console.log(error);
            })
      
       
    }

    
    const setUserData = (data) =>{
        setUserToken(data.token);
        setUrlToken(process.env.REACT_APP_URL_TOKEN === "true" ? `?token=${data.token}` : '')
        setUserName(data.screenName);
        setUserId(data.userId);
        setTeamName(data.teamName ? data.teamName : "");
        
        setBalance(typeof(data.balance) !== 'undefined' ? data.balance : null);   
        //setPlayMode('real');  
        //data.template = "ladbrokes";
        setTemplate(data.template);
        setLanguageCode(data.languageCode);
        setCompanyName(data.companyName);
        setOperatorName(data.operatorName);
        setCasinoLobbyUrl(data.casinoLobbyUrl);
        setCpuLevelUp(data.cpuLevelUp === 1 ? true:false);
        setDownForMaintenance(data.downForMaintenance === 1 ? true:false);
        setScreenNameRequired(data.screenNameRequired === 1 ? true:false);
        setfaq(data.faq);
        if(data.operatorName === "hb"){
            localStorage.removeItem("hbToken")
            localStorage.setItem("hbToken", JSON.stringify(data.token));
        }
        
        loadTemplate(data.template);
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    const loadTemplate =(theme) =>{
      if(theme){
        if(theme !== '' || theme !== 'default'){
          const cssUrl = process.env.PUBLIC_URL +`/assets/${theme}/styles.css`;
          const style = document.createElement("link");
          style.href = cssUrl;
          style.rel = "stylesheet";
          style.async = true;
          document.head.appendChild(style);
        }
      }
    }
    useLayoutEffect(()=>{
        setErrorMessage('');
        authenticateUser(window.location.href);
    
    },[])
    const value = {
        userToken,
        userId,
        userName,
        languageCode,
        template,
        companyName,
        operatorName,
        casinoLobbyUrl,
        teamName,
        faq,
        balance,
        loggedIn,
        showLogin,
        errorMessage,
        showPopup,
        ctaBodyText,
        ctaBtnText,
        ctaBtnLink,
        playMode,
        screenNameRequired,
        cpuLevelUp,
        downForMaintenance,
        urlToken,
        setBalance,
        setShowPopup,
        setCtaBodyText,
        setCtaBtnText,
        setCtaBtnLink,
        onAuthenticate: authenticateUser,
        onLogin:handleLogin,
        onLogout: handleLogout,
        onRegister:registerUser,
        onScreenNameUpdate:screenNameUpdate
    }

    return(
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )

}

export default AuthProvider;