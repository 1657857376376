
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../generic/components/ProtectRoute';
import React,{ useContext, useEffect, useLayoutEffect, useState} from "react";
import "../services/i18n";
import 'bootstrap/dist/css/bootstrap.min.css';

import Terms from "../generic/pages/Terms";
import Login from "../generic/pages/Login";
import Help from './pages/Help';
import MyTournaments from "../generic/pages/MyTournaments";
import NoPage from "../generic/pages/NoPage";
import MyBattles from '../generic/pages/MyBattles';
import GameDetails from '../generic/pages/GameDetails';
import ArchiveMyTournaments from '../generic/pages/ArchiveMyTournaments';
import ArchiveMyBattles from '../generic/pages/ArchiveMyBattles';
import ArchiveGameDetails from '../generic/pages/ArchiveGameDetails';

import Layout from "../generic/pages/Layout";
import Lobby from "../generic/pages/Lobby";
import Register from "../generic/pages/Register";
import AuthProvider from '../generic/components/AuthProvider';
import '../assets/css/styles.css';
import '../assets/ladbrokes/styles.css';
import { useTranslation } from "react-i18next";

const appVersion = "ladbrokes.1.0.8";
console.log(appVersion);
function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
      document.title = "SlotMasters";
      i18n.changeLanguage("ladbrokes");
  }, []);
  
  return (
    <>

    {(() => {
     
      return (<BrowserRouter><AuthProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route index element={<Lobby />} />
              <Route path="mytournaments" element={<MyTournaments />} />
              <Route path="mybattles/:tournamentid" element={<MyBattles />} />
              <Route path="gamedetails/:gameid/:tournamentid" element={<GameDetails />} />
              <Route path="archiveMyTournaments" element={<ArchiveMyTournaments />} />
              <Route path="archivemybattles/:tournamentid" element={<ArchiveMyBattles />} />
              <Route path="archivegamedetails/:gameid/:tournamentid" element={<ArchiveGameDetails />} />
              <Route path="terms" element={<Terms />} />
              <Route path="help" element={<Help />} />
              <Route path="*" element={<NoPage />} />  

            </Route>
          </Routes>
      </AuthProvider></BrowserRouter>)
     

  })()}
    </>
    
   
  );
}

export default App;
