import { useParams } from "react-router-dom";
import React,{ useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserContext from "../../generic/components/appContext";
import axios from "axios";

const GameDetails = () => {
  const paraData = useParams();
  const [id, setId] = useState(paraData.gameid);
  const { userId,userToken, urlToken } = useContext(UserContext);
  const [tournamentId, setTournamentId] = useState(paraData.tournamentid);
  const [battleData, setBattleData] = useState();
  const [battleSummaryDate, setBattleSummaryDate] = useState();
  const [battleSummaryPlaces, setBattleSummaryPlaces] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState(-(new Date().getTimezoneOffset()*60*1000)); //*60*1000

  const getGameDetails = async () =>{
    setLoading(true);
    return await axios
        .get(`${process.env.REACT_APP_DATABASE_URL}/api/lobby/mygames/view/${id}`)
        .then((response) => {
            if(response && response.data && response.data.spinHistory){
                setBattleData(response.data.spinHistory);
            }
            if(response && response.data && response.data.battleSummary){
                    
                setBattleSummaryDate(response.data.battleSummary.playedDate);
                const placesAscending = response.data.battleSummary.playerPoints.sort((a, b) => a.place - b.place);
                setBattleSummaryPlaces(placesAscending);
              }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect (() =>{
        getGameDetails();
        
    },[])


    return (
      <div className="container-page">
            <div className="content-page">
                <div className="row gx-1 mb-1">
                    <div className="col-9">
                      <h5>Game ID #{id}</h5>
                    </div>
                    <div className="col-3">
                        <div className="text-end"><Link className="text-link" to={'/mybattles/'+tournamentId+urlToken}>{t("BACK")}</Link></div>
                    </div>
                </div>
                {battleSummaryDate &&
                <div className="row gx-1 mb-3">
                    <div>
                    {t("GAME_PLAYED_ON")} {new Intl.DateTimeFormat("en-GB", { 
                                            year: "numeric",
                                            month: "numeric",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: false,
                                        }).format(new Date(battleSummaryDate))}
                    
                    </div>
                    <div>
                    {battleSummaryPlaces && battleSummaryPlaces.map((data,index) => (
                      <div key={index}>
                      {data.place}) {data.name} - {data.points}
                      </div>
                    ))
                    }
                    </div>
                    
                </div>
                }
                <div className="card-table">
                    <div className="row gx-1">
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("ACTION")}</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("PLAYER")}</div>
                        </div>
                      
                        <div className="col-4 table-col">
                            <div className="p-1 title">{t("SCORE")}</div>
                        </div>
                        <div className="col-2 table-col">
                            <div className="p-1 title">{t("TIME")}</div>
                        </div>
                    </div>
               
                    {battleData && battleData.map((data,index) => (
                        <div key={index} className={`row gx-1 table-row ${data.targetPlayerName !== null ? "highlight" : "" }`}>
                            <div className="col-3 table-col">
                                <div className="p-1">{index+1}) {t(data.name.toUpperCase())}    </div>
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1">{data.playerName}</div>
                            </div>

                            <div className="col-4 table-col">
                                <div className="p-1">{data.targetPlayerName !== null ? "("+data.targetPlayerName+")" : ""} {data.playerTotalWinnings}</div>
                            </div>
                            <div className="col-2 table-col">
                                <div className="p-1">{new Intl.DateTimeFormat("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric",
                                            hour12: false
                                        }).format(new Date(data.playDate.replace(/ /g,"T")).getTime()+timeZone)}
                                 </div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
              </div>
            </div>
      
    );
    
};
  
  export default GameDetails;